import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class AppFooterComponent implements OnInit {

    year: number = 2019;

    ngOnInit(): void {
        const today = new Date();
        this.year = today.getFullYear();
    }
}
