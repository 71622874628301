import { AppHeaderComponent } from './components/app-header/header.component';
import { AppFooterComponent } from './components/app-footer/footer.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { HomeHeaderComponent } from './components/home-header.component';
import { HomeFooterComponent } from './components/home-footer.component';
import { HomeBreadcrumbComponent } from './components/home-breadcrumb.component';
import { HomeLeftMenuComponent } from './components/left-menu/leftmenu.component';
import { BaseLayoutComponent } from './components/base/base-layout.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { PagerComponent } from './components/pager/pager.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { WeatherMapComponent } from './components/weather-map/weather-map.component';
import { ImageWithLoadingComponent } from './components/image-with-loading/image-with-loading.component';
import { PlantSearchPopupComponent } from './components/plant-search-popup/plant-search-popup.component';

export const SHARED_COMPONENTS = [
    AppHeaderComponent,
    AppFooterComponent,
    BaseLayoutComponent,
    ConfirmDialogComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    HomeBreadcrumbComponent,
    HomeLeftMenuComponent,
    ModalPopupComponent,
    PagerComponent,
    PaginatorComponent,
    WeatherMapComponent,
    ImageWithLoadingComponent,
    PlantSearchPopupComponent
];

export * from './adapters';
export * from './components/app-header/header.component';
export * from './components/app-footer/footer.component';
export * from './components/base/base-layout.component';
export * from './components/confirm-dialog/confirm-dialog.component';
export * from './components/left-menu/leftmenu.component';
export * from './components/home-header.component';
export * from './components/home-footer.component';
export * from './components/home-breadcrumb.component';
export * from './components/modal-popup/modal-popup.component';
export * from './components/pager/pager.component';
export * from './components/paginator/paginator.component';
export * from './components/weather-map/weather-map.component';
export * from './components/image-with-loading/image-with-loading.component';
export * from './components/plant-search-popup/plant-search-popup.component';
export * from './formatters';
