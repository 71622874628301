import { FilterModel } from './filter.model';

export module LandscapeStyleModule {

  export class LandscapeStyleModel {
    Id: number;
    Name: string = '';
    Description: string = '';
    StatustypeId: number = 1;
  }

  export interface ListingModel extends LandscapeStyleModel {
    Thumbnail: string;
    Plants: number;
    Images: number;
    StatusId: number;
    StatusName: string;
    TotalRows: number;
  }

  export class SingleModel extends LandscapeStyleModel {
    SunRequirementId: number = 0;
    AltSunRequirementId: number = -1;
    WaterRequrementId: number = -1;
    SoilDepth: number = 0;
    MulchDepth: number = 0;
    OtherMaterials: string = '';
    GrowthRateId: number = -1;
    EffortLevelId: number = -1;
    AttractsPollinators: number = 0;
    Cost: number = -1;
    CreatedbyPortaluserName: string = '';
  }

  export class PlantModel {
    PlantId: number;
    CommonName: string;
    BotanicalName: string;
    Thumbnail: string;
    DisplayOrder: number;
    TotalRows: number;
  }

  export class ImageModel {
    Id: number;
    Thumbnail: string;
    WebImage: string;
    FullImage: string;
    Author: string;
    SourceFilename: string;
    Latitude?: number;
    Longitude?: number;
    CreatedAt: Date;
    IsDefault: boolean;
    StatusTypeId: number;
    UserImageId: number;
    FileSize: number;
    Width: number;
    Height: number;
    IsForImageRec: number;
    Selected: boolean;
    TotalRows: number;
  }

  export class ImageValidateModel {
    Thumbnail: boolean = false;
    Author: boolean = false;
  }

  export class ImageFilterModel extends FilterModel {
    primary: boolean;
    published: boolean;
    unpublished: boolean;
  }
}
