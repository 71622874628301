import { LoadingService } from './loading/loading.service';
import { LandscapeStyleService } from './landscape-style/landscape-style.service';
import { ModalService } from './modal/modal.service';
import { SeasonService } from './season/season.service';
import { UsersService } from './users/users.service';
import { WeatherStationService } from './weather-station/weather-station.service';
import { GalleryService } from './gallery/gallery.service';
import { IRAdminService } from './ir-admin/ir-admin.service';
import { SubscribersService } from './subscribers.service';

export const SERVICES = [
  GalleryService,
  IRAdminService,
  LandscapeStyleService,
  LoadingService,
  ModalService,
  SeasonService,
  SubscribersService,
  UsersService,
  WeatherStationService
]

export * from './authentication.service';
export * from './category.service';
export * from './common.service';
export * from './gallery/gallery.service';
export * from './ir-admin/ir-admin.service';
export * from './landscape-style/landscape-style.service';
export * from './loading/loading.service';
export * from './modal/modal.service';
export * from './partners.service';
export * from './season/season.service';
export * from './subscribers.service';
export * from './users/users.service';
export * from './utils.service';
export * from './weather-station/weather-station.service';
