import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from './../../environments/environment';
import { CategoryModel, CareUpdateInfoModel, GenericResponseModel, TaskRuleModel } from '../models';
import { CampaignModel } from '../models/campaign.model';
import { ProgramModel } from '../models/program.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoryService {

    constructor(private http: HttpClient) { }

    getAvailableCategories(): Observable<CategoryModel[]> {
        return this.http.get<CategoryModel[]>(`${environment.apiUrl}/Categories/1/GetAvailableCategories`);
    }

    getOverviewMatrix() {
        return this.http.get<any>(`${environment.apiUrl}/Categories/1/OverviewMatrix`);
    }

    getOverviewMatrixCsv() {
        return this.http.get<any>(`${environment.apiUrl}/Categories/1/OverviewMatrixCsv`);
    }

    getCareCategoryInfo(categoryID: number, seasonCategoryID: number) {
        return this.http.get<CareUpdateInfoModel>(`${environment.apiUrl}/Categories/${categoryID}/CareUpdateInfo/${seasonCategoryID}`);
    }    

    getSuggestedProductsCampaigns(programID: number) {
        const headers = new HttpHeaders().set('ignoreLoadingBar', '');
        return this.http.get<CampaignModel[]>(`${environment.apiUrl}/programs/0/SuggestedProducts/${programID}`, { headers: headers });
    }

    getSuggestedProductsPrograms() {
        return this.http.get<ProgramModel[]>(`${environment.apiUrl}/programs/0/SuggestedProducts`);
    }

    saveCareUpdateInfo(request: CareUpdateInfoModel) {
        return this.http.post<GenericResponseModel>(`${environment.apiUrl}/CareUpdates/0/CareUpdateInfo`, request);
    }    

    saveCareUpdateTaskRule(request: TaskRuleModel) {
        return this.http.post<TaskRuleModel>(`${environment.apiUrl}/CareUpdates/${request.TaskId}/TaskRule`, request);
    }       

    deleteCareUpdateTaskRule(request: TaskRuleModel): Observable<GenericResponseModel> {
        return this.http.post<GenericResponseModel>(`${environment.apiUrl}/CareUpdates/${request.CareUpdateId}/DeleteTaskRule`, request);
    }
}
