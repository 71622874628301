import { dateSelectionJoinTransformer } from '@fullcalendar/core';

export class IRTrainingLogModel {
  Id: number;
  Starttime: Date;
  Endtime: Date;
  ImagesProcessed: number;
  ModelId: string;
  IsActive: boolean;
}

export class IRTrainingLogListingModel extends IRTrainingLogModel {
  TimeSpent: string;
  TotalRows: number;
}

export class IRSettingModel {
  TrainNow: boolean;
  Starttime: string;
}

export class ImageSearchResultModel {
  Id: number;
  Thumbnail: string;
  WebImage: string;
  SearchDate: Date;
  ResultsCount: number;
  ResolvedDate: Date;
  TotalRows: number;
  selected: boolean;
}
