export class SessionModel {
    isAuth: boolean;
    TokenKey: string;
    Fullname: string;
    Photo: string;
    UserID: number;
    RoleID: number;
    RoleName: string;
    LastLoginDate: string;
    Notes: string;
    ExpirationTimeout: number;
  }