export class PartnerSMSModel {
  Id: number;
  HELPMessage: string;
  MessagingServiceSid: string;
  OptInThankMessage: string;
  OutboundMessage: string;
  ReponseMessage: string;
  ShortCode: string;
  STOPMessage: string;
  STOPMessageWithUrl: string;
}
