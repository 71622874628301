import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthenticationService, CategoryService, UtilsService } from './../../../services';
import { CategoryModel, SessionModel } from './../../../models';

declare var $: any;
@Component({
    selector: 'home-leftmenu',
    templateUrl: './leftmenu.component.html',
    styleUrls: ['./leftmenu.component.css']
})
export class HomeLeftMenuComponent implements OnInit, OnDestroy, AfterViewInit {
    authentication: SessionModel;
    categorySub: Subscription;
    categories: CategoryModel[] = [];
    roleId: number = 0;

    constructor(
        private authenticationService: AuthenticationService,
        private categoryService: CategoryService
    ) { }

    ngOnInit(): void {
        this.authentication = this.authenticationService.Identity;
        this.roleId = this.authenticationService.Identity.RoleID;

        this.fetchData();
    }

    ngOnDestroy(): void {
        UtilsService.Unsubscribe(this.categorySub);
    }

    ngAfterViewInit(): void {
        this.reloadTreeview();
    }

    fetchData(): void {
        this.categorySub = this.categoryService.getAvailableCategories()
            .subscribe(
                data => {
                    this.categories = data;
                },
                error => {
                    console.error(error);
                });
    }

    reloadTreeview(): void {
        $(document).ready(() => {
            const trees: any = $('[data-widget="treeview"]');
            if (trees) {
                trees.init();
            }
        });
    }
}
