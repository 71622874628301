import { Component, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

import { Subscription } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';

import { LoadingService, UtilsService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'adminLTE-app';
  private routerSub: Subscription;
  private loaderSub: Subscription;
  private updateCounter: number;

  constructor(
    private router: Router,
    private loadingBar: LoadingService,
    private spinner: NgxSpinnerService
  ) {
    this.updateCounter = 0;
    this.routerSub = this.router.events
      .subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      });

    this.loaderSub = this.loadingBar.state$
      .subscribe(val => {
        if (val) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      });
  }

  ngOnDestroy(): void {
    UtilsService.Unsubscribe(this.routerSub);
    UtilsService.Unsubscribe(this.loaderSub);
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loadingBar.show();
    }
    if (event instanceof NavigationEnd) {
      this.loadingBar.hide();
    }
    if (event instanceof NavigationCancel) {
      this.loadingBar.hide();
    }
    if (event instanceof NavigationError) {
      this.loadingBar.hide();
    }
  }
}
