export class UserValidModel {
  Company: boolean = true;
  FirstName: boolean = true;
  LastName: boolean = true;
  PhoneNumber: boolean = true;
  Email: boolean = true;
  CurrentPassword: boolean = true;
  NewPassword: boolean = true;
  ConfirmPassword: boolean = true;
  MissingFields: boolean = true;
  PasswordComplexy: boolean = true;
  InvalidEmailFormat: boolean = true;
  InvalidPhoneFormat: boolean = true;
  PasswordMatch: boolean = true;
}

export class UserModel {
  UserID: number;
  Email: string;
  FirstName: string;
  LastName: string;
  Company: string;
  AddressLine1: string;
  AddressLine2: string;
  LastLoginDate: string;
  City: string;
  ZipCode: string;
  StateID: number;
  CountryID: number;
  PhoneNumber: string;
  Extension: string;
  RoleID: number;
  RoleName: string;
  Notes: string;
  Photo: string;
  TotalRows: number;
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
  PartnerID: number;
  RotAngle: number;
  IsActive: boolean;

  validate(validModel: UserValidModel, validPass: boolean): boolean {
    var isValid = true;

    if (undefined == this.Company || "" == this.Company) {
      validModel.MissingFields = false;
      validModel.Company = false;
      isValid = false;
    }

    if (undefined == this.FirstName || "" == this.FirstName) {
      validModel.MissingFields = false;
      validModel.FirstName = false;
      isValid = false;
    }

    if (undefined == this.LastName || "" == this.LastName) {
      validModel.MissingFields = false;
      validModel.LastName = false;
      isValid = false;
    }

    if (undefined == this.Email || "" == this.Email) {
      validModel.MissingFields = false;
      validModel.Email = false;
      isValid = false;
    } else if (!this.validateEmail(this.Email)) {
      validModel.InvalidEmailFormat = false;
      validModel.Email = false;
      isValid = false;
    }

    if (undefined == this.PhoneNumber || "" == this.PhoneNumber) {
      validModel.MissingFields = false;
      validModel.PhoneNumber = false;
      isValid = false;
    } else if (!this.validatePhoneNumber(this.PhoneNumber)) {
      validModel.InvalidPhoneFormat = false;
      validModel.PhoneNumber = false;
      isValid = false;
    }

    if ((this.UserID > 0) && (validPass)) {
      if (!this.strIsNullOrEmpty(this.ConfirmPassword) || !this.strIsNullOrEmpty(this.NewPassword)) {
        if (undefined == this.CurrentPassword || "" == this.CurrentPassword) {
          validModel.MissingFields = false;
          validModel.CurrentPassword = false;
          isValid = false;
        }

        if (undefined == this.ConfirmPassword || "" == this.ConfirmPassword) {
          validModel.MissingFields = false;
          validModel.ConfirmPassword = false;
          isValid = false;
        }

        if (!this.validatePassword(this.NewPassword)) {
          validModel.PasswordComplexy = false;
          validModel.NewPassword = false;
          isValid = false;
        }

        if (this.NewPassword != this.ConfirmPassword) {
          validModel.PasswordMatch = false;
          validModel.NewPassword = false;
          validModel.ConfirmPassword = false;
          isValid = false;
        }
      }
    }

    return isValid;
  }

  private strIsNullOrEmpty(value: string): boolean {
    return value == null || value == undefined || value == "";
  }

  private validatePhoneNumber(str: string): boolean {
    let re = /^\d{10}$/;
    if (!re.test(str)) {
      return false;
    }
    return true;
  }

  private validatePassword(str: string): boolean {
    let re = /^((?=.*\d)(?=.*[a-zA-Z])(?=.*[@#$%]).{8,40})$/;
    if (!re.test(str)) {
      return false;
    }
    return true;
  }

  private validateEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      return false;
    }

    return true;
  }
}

export class Dashlet {
  V1: number;
  V2: number;
  V3: number;
  V4: number;
  V5: number;
  V6: number;
  V7: number;
  V8: number;
  V9: number;
  V10: number;
  V11: number;
  V12: number;
}

export class Yard {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  postalcode: string;
  latitude: number;
  longitude: number;
  stateId: number;
  countryId: number;
  weatherStationId: number;

  constructor() {
    this.stateId = 0;
    this.latitude = 0;
    this.longitude = 0;
  }
}
