export class WeatherStationModel {
  StationID: number;
  MonitorStationIdentifier: string;
  WeatherStationIdentifier: string;
  Name: string;
  State: string;
  Latitude: number;
  Longitude: number;
  Elevation: number;
  AverageMaxTemp: number;
  AverageMinTemp: number;
  AverageFirstFreezeDate: string;
  AverageLastFreezeDate: string;
  AveragePrecipitation: number;
  IsExtremeHeatZone: boolean
  CurrentTemperature: number;
  FirstFreeze: string;
  LastFreeze: string;
  AverageMaxTempCelcious: number;
  AverageMinTempCelcious: number;
  AveragePrecipitationCm: number;
  MonthHighTemp: number;
  MonthHighTempCelcious: number;
  MonthLowTemp: number;
  MonthLowTempCelcious: number;
  MonthAvgTemp: number;
  MonthAvgTempCelcious: number;
  MonthPrecipitation: number;
  MonthPrecipitationCm: number;
  InfoWindow?: string;
}
