<div class="wrapper">
    <!-- Main Header -->
    <home-header></home-header>
    <!-- Left side column. contains the sidebar -->
    <home-leftmenu></home-leftmenu>
    <!-- Content -->
    <div class="content-wrapper">
        <router-outlet #o="outlet"></router-outlet>
    </div>
    <!-- Main Footer -->
    <home-footer></home-footer>
</div>