import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';

import { GalleryPagingModel, GalleryModel, getDefaultGalleryPaging, ImageDetailModel, GenericResponseModel } from './../../models';
import { AuthenticationService } from './../authentication.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class GalleryService {
    imageGallery$: BehaviorSubject<GalleryModel[]> = new BehaviorSubject([]);
    imageGalleryFilters$: BehaviorSubject<GalleryPagingModel> = new BehaviorSubject(getDefaultGalleryPaging());

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService
    ) { }

    applyFilters(options: GalleryPagingModel): void {
        this.imageGalleryFilters$.next(options);

        this.getGallery();
    }

    getGallery(): void {
        const filters: GalleryPagingModel = this.imageGalleryFilters$.getValue();

        let apiUrl = `/Gallery/${this.authService.Identity.UserID}/GetCategoriesGallery/?key=${filters.Keyword}&pI=${filters.PageIndex}&pS=${filters.PageSize}&sD=${filters.StartDate}&eD=${filters.EndDate}&jF=${filters.JustFavorites}&fp=${filters.PlantId}&fc=${filters.CategoryId}`;
        this.http.get<GalleryModel[]>(environment.apiUrl + apiUrl)
            .subscribe(response => {
                this.imageGallery$.next(response);
            });
    }

    putUpdateFavorites(imageIDCSV: string, isDeleteAction: boolean): Observable<any> {
        let apiUrl = `/Gallery/0/SetFavorites`;
        const action = {
            Ids: imageIDCSV,
            IsFavorite: isDeleteAction
        };
        return this.http.put(environment.apiUrl + apiUrl, action);
    }

    getImageDetail(id: number): Observable<ImageDetailModel> {
        return this.http.get<ImageDetailModel>(environment.apiUrl + `/Gallery/${id}/`);
    }

    deleteGalleryImage(id: number): Observable<GenericResponseModel> {
        return this.http.delete<GenericResponseModel>(environment.apiUrl + `/Gallery/${id}/`);
    }

    downloadImages(ids: string): Observable<any> {
        let apiUrl = `/Gallery/0/Download/?key=${ids}&sp=0`;
        return this.http.get<any>(environment.apiUrl + apiUrl, { responseType: 'blob' as 'json' });
    }
}
