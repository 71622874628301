<div class="modal fade" data-backdrop="static" data-keyboard="false" id="{{id}}Modal" tabindex="-1" role="dialog" aria-labelledby="popupLabel"
  aria-hidden="true">
  <div class="modal-dialog" [ngClass]="{ 'modal-sm': size === 'sm', 'modal-lg': size === 'lg', 'modal-xl': size === 'xl' }" role="document">
    <div class="modal-content">
      <div class="modal-header bg-{{dialogType}}">
        <h5 class="modal-title" [class.text-white]="dialogType === 'danger' || dialogType === 'warning'" id="popupLabel">
          {{ headerText }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="onActionExecute('cancel')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer" *ngIf="(cancelText && cancelText.length > 0) || (confirmText && confirmText.length > 0)">
        <ng-content select="[slot=actions]"></ng-content>
        <button *ngIf="cancelText && cancelText.length > 0" type="button" class="btn btn-{{cancelBtnClass}}" (click)="onActionExecute('cancel')">{{ cancelText }}</button>
        <button *ngIf="confirmText && confirmText.length > 0" type="button" class="btn btn-{{confirmBtnClass}}" (click)="onActionExecute('confirm')">{{ confirmText }}</button>
      </div>
    </div>
  </div>
</div>
