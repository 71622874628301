import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UtilsService {

    public static Unsubscribe(sub: Subscription): void {
        if (sub) {
            sub.unsubscribe();
        }
    }

    public static validatePhoneNumber(str: string): boolean {
        let re = /^\d{10}$/;
        if (!re.test(str)) {
            return false;
        }
        return true;
    }

    public static validatePassword(str: string): boolean {
        let re = /^((?=.*\d)(?=.*[a-zA-Z])(?=.*[@#$%]).{8,40})$/;
        if (!re.test(str)) {
            return false;
        }
        return true;
    }

    public static validateEmail(email: string): boolean {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            return false;
        }

        return true;
    }

    public static handleError(err: any): string {
        let errormsg: string = "";
        var error = err.json();
        if ((error !== undefined) && (error.ExceptionMessage !== undefined))
            errormsg = error.ExceptionMessage;

        return UtilsService.handleErrorMessages(errormsg);
    }

    public static handleErrorMessages(err: string): string {
        let errormsg: string = "An error has occurred while processing your request.";

        if (err == "AccountNotFound") {
            errormsg = "The account provided does not match any of our records.";
        } else if (err == "AccountNotApproved") {
            errormsg = "The account provided is not approved yet. Please contact Administrator.";
        } else if (err == "InvalidKey") {
            errormsg = "The reset key is invalid or expired. Please go to forgot password to start the process again.";
        } else if (err == "InvalidRegisterID") {
            errormsg = "The registration key is invalid or expired. Please contact Administrator.";
        } else if (err == "DuplicateEmail") {
            errormsg = "Email address is already registered. Please change.";
        } else if (err == "DeleteSameUser") {
            errormsg = "The user cannot be deleted because is the same user logged.";
        } else if (err == "InvalidInformation") {
            errormsg = "The account provided does not match any of our records.";
        } else if (err == "InvalidCredentials") {
            errormsg = "Login attempt failed. Please contact support for assistance.";
        } else if (err == "OldPasswordMatch") {
            errormsg = "Old password is not valid.";
        } else if (err == "UserInactive") {
            errormsg = "The account provided is not active. Please contact Administrator.";
        } else {
            errormsg = "An error has occurred while processing your request.";
        }

        return errormsg;
    }

    public static pluck(o, propertyNames): string[] {
        return propertyNames.map(n => o[n]);
    }
}
