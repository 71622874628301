import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { PlantFilterModel, ImageDetailModel, DefaultPagerOption } from './../../../models';
import { AuthenticationService, UtilsService } from './../../../services';
import { PlantsService } from './../../../services/plants.service';

@Component({
  selector: 'plant-search-popup',
  templateUrl: './plant-search-popup.component.html',
  styleUrls: ['./plant-search-popup.component.scss']
})
export class PlantSearchPopupComponent implements OnDestroy {
  @Output() actionExecute: EventEmitter<number> = new EventEmitter<number>();

  submitted = false;
  searchForm: UntypedFormGroup;
  pageSize: number = DefaultPagerOption;
  filter: PlantFilterModel;
  data: ImageDetailModel[] = [];
  selected: ImageDetailModel;
  sortColumn = 'Fullname';
  sortType: 'ASC' | 'DESC' = 'ASC';

  searchPlantSub: Subscription;

  totalRows: number;
  totalItems: number;
  currentPage: number;

  constructor(
    private authService: AuthenticationService,
    private plantService: PlantsService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      criteria: ['']
    });
  }

  init(): void {
    this.totalItems = 0;
    this.totalRows = 0;
    this.currentPage = 0;

    this.filter = new PlantFilterModel();
    this.filter = Object.assign({}, this.filter, { c: (+this.authService.Identity.Notes || 25), s: 'CommonName ASC' });
    this.selected = undefined;
    this.searchForm.get("criteria").setValue("");

    this.performSearch();
  }

  ngOnDestroy(): void {
    UtilsService.Unsubscribe(this.searchPlantSub);
  }

  get f() { return this.searchForm.controls; }

  performSearch(): void {
    this.totalItems = 0;
    this.totalRows = 0;
    this.filter.i = this.currentPage;
    this.filter.c = this.pageSize;
    this.filter.k = this.f.criteria.value;
    this.filter.s = `${this.sortColumn} ${this.sortType}`;

    this.searchPlantSub = this.plantService.searchPlants(this.filter)
      .subscribe(data => {
        this.data = data;
        if (this.data) {
          this.totalRows = this.data.length;
          this.totalItems = parseInt(data[0].TotalRows, 0);
        }
      });
  }

  onPagerChanged(newPageSize: number): void {
    this.currentPage = 0;
    this.pageSize = newPageSize;
    this.performSearch();
  }

  onPageChanged(newPageIndex: number): void {
    this.currentPage = newPageIndex;
    this.performSearch();
  }

  selectPlant(plant: ImageDetailModel): void {
    this.selected = plant;
  }

  sortBy(column: string) {
    if (this.sortColumn === column) {
      this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortColumn = column;
    }
    this.performSearch();
  }
}
