import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FilterModel, GenericResponseModel, LandscapeStyleModule, PlantFilterModel, Store } from './../../models';
import { environment } from './../../../environments/environment';
import { Utils } from './../../helpers/utils';
import { PlantDetailModel, PlantModel } from 'src/app/models/plant.model';

@Injectable()
export class LandscapeStyleService extends Store<LandscapeStyleModule.LandscapeStyleModel>{

  constructor(private http: HttpClient) {
    super(new LandscapeStyleModule.LandscapeStyleModel());
  }

  searchStyles(filter: FilterModel): Observable<LandscapeStyleModule.ListingModel[]> {
    return this.http.get<LandscapeStyleModule.ListingModel[]>(`${environment.apiUrl}/LandscapeStyles/0/Search/?` + Utils.qs(filter));
  }

  getStyle(id: number): Observable<LandscapeStyleModule.SingleModel> {
    return this.http.get<LandscapeStyleModule.SingleModel>(`${environment.apiUrl}/LandscapeStyles/${id}/`);
  }

  saveStyle(id: number, entity: LandscapeStyleModule.SingleModel): Observable<GenericResponseModel> {
    if (id === 0) {
      return this.http.post<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/0/`, entity);
    } else {
      return this.http.put<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${id}/`, entity);
    }
  }

  deleteStyle(styleId: number): Observable<GenericResponseModel> {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/`);
  }

  searchPlants(styleId: number, filter: FilterModel): Observable<LandscapeStyleModule.PlantModel[]> {
    return this.http.get<LandscapeStyleModule.PlantModel[]>(`${environment.apiUrl}/LandscapeStyles/${styleId}/PlantSearch/?` + Utils.qs(filter));
  }

  deletePlant(styleId: number, plantId: number): Observable<GenericResponseModel> {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Plant/${plantId}/`);
  }

  updatePlantOrder(styleId: number, plantOrder: LandscapeStyleModule.PlantModel[]): Observable<GenericResponseModel> {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/PlantOrder/`, plantOrder);
  }

  searchImages(styleId: number, filter: LandscapeStyleModule.ImageFilterModel): Observable<LandscapeStyleModule.ImageModel[]> {
    return this.http.get<LandscapeStyleModule.ImageModel[]>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Images/?` + Utils.qs(filter));
  }

  updateImageOrder(styleId: number, imageOrder: LandscapeStyleModule.ImageModel[]): Observable<GenericResponseModel> {
    return this.http.patch<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Images/`, imageOrder);
  }

  bulkActions(styleId: number, action: number, images: LandscapeStyleModule.ImageModel[]): Observable<GenericResponseModel> {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/ImageActions/${action}/`, images);
  }

  saveImage(styleId: number, image: LandscapeStyleModule.ImageModel): Observable<GenericResponseModel> {
    if (image.Id > 0) {
      return this.http.put<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Images/${image.Id}/`, image);
    } else {
      return this.http.post<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Images/`, image);
    }
  }

  searchAddPlants(styleId: number, filter: any): Observable<PlantModel[]> {
    return this.http.get<PlantModel[]>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Plants/?` + Utils.qs(filter));
  }

  addPlant(styleId: number, plantId: number): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/LandscapeStyles/${styleId}/Plant/${plantId}/`, null);
  }
}
