import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'home-footer',
    templateUrl: './home-footer.component.html',
    styleUrls: ['./home-footer.component.css']
})
export class HomeFooterComponent implements OnInit {

    currentYear: number = 0;

    constructor() { }
  
    ngOnInit() {
      this.currentYear = new Date().getFullYear();
    }
}
