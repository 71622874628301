export class FilterModel {
  k: string = '';
  s: string = '';
  desc: number = 0;
  i: number = 0;
  c: number = 0;
  cid: number = 0;
}

export class PlantFilterModel {
  k: string = '';//CommonName
  s: string = '';//SortBy
  i: number = 0;//Page Index
  c: number = 0;//Page Size
  cid: number = 0;//CategoryId
  b: string = '';//Botanical Name
  lid: number = 0;//LifeCycleId
  sid: number = 0;//SunRequirementId
  wid: number = 0;//WaterRequirementId
  desc: number = 0;//Descending?
  f: string = '';//Fuzzy Operator
  t: string = '';//Tag Search IR
  ir: string = '';//G=Groomed,N=NeedsGrooming,A=AdminGrooming
  st: string = '';//P=Published,D=Draft,V=Private
}

export class PlantImageFilterModel {
  k: string = '';
  s: string = '';
  i: number = 0;
  c: number = 0;
  cid: number = 0;
  primary: boolean = false;
  published: boolean = false;
  aiGenerated: boolean = false;
  nap: boolean = false;
  imagerecognition: boolean = false;
  imp: boolean = false;
  del: boolean = false;
}

export class GalleryFilterModel {
  k: string;
  sd: string;
  ed: string;
}

export class GalleryPagingModel {
  Keyword: string = '';
  StartDate: number = null;
  EndDate: number = null;
  OrderBy: string;
  PageIndex: number;
  PageSize: number;
  JustFavorites: boolean;
  CategoryId: number;
  PlantId: number;
}

export interface PageParams {
  keyword: string;
  sort: string;
  reverse: boolean;
  size: number;
  page: number;
}

export const getDefaultGalleryPaging = () => ({
  PageIndex: 0,
  PageSize: 4,
  OrderBy: 'FirstName ASC',
  JustFavorites: false,
  CategoryId: 0,
  PlantId: 0,
  Keyword: '',
  StartDate: undefined,
  EndDate: undefined
});

export const getDefaultGalleryPagingFavs = () => ({
  PageIndex: 0,
  PageSize: 4,
  OrderBy: 'FirstName ASC',
  JustFavorites: true,
  CategoryId: 0,
  PlantId: 0,
  Keyword: '',
  StartDate: undefined,
  EndDate: undefined
});

export class GRFilterModel {
  s: string = '';
  fd: number = 0;
  td: number = 0;
  i: number = 0;
  c: number = 0;
  gid: number = 0;
}


export interface ReferralCode{
  Id: number;
  Code: string;
  IsActive: boolean;
  Startdate: Date;
  NoEndDate: boolean;
  Enddate: Date;
  ReferralAmount: number;
  SubscriptionId: number;
  SubscriptionLabel: string;
}
