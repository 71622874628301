export enum BulkActions {
  Select,
  GenerateQR,
  Delete
}

export const BulkActionLabels = new Map<number, string>([
  [BulkActions.Select, '-Select-'],
  [BulkActions.GenerateQR, 'Generate QR'],
  [BulkActions.Delete, 'Delete All']
]);
