<div class="modal fade" id="{{id}}Modal" tabindex="-1" role="dialog" aria-labelledby="confirmLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-{{dialogType}}">
        <h5 class="modal-title" [class.text-white]="dialogType === 'danger' || dialogType === 'warning'" id="confirmLabel">
          {{ headerText }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="onActionExecute('cancel')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ messageText }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onActionExecute('cancel')">{{ cancelText }}</button>
        <button type="button" class="btn btn-{{dialogType}}" (click)="onActionExecute('confirm')">{{ confirmText }}</button>
      </div>
    </div>
  </div>
</div>
