import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { SeasonModel } from './../../models';

@Injectable()
export class SeasonService {

    seasons: SeasonModel[] = [];

    constructor(private http: HttpClient) { }

    getAll(): Observable<SeasonModel[]> {
        return this.http.get<SeasonModel[]>(`${environment.apiUrl}/seasons/`);
    };
}
