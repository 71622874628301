import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { ModalService } from './../../../services';

declare var $: any;

@Component({
  selector: 'modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.css']
})
export class ModalPopupComponent implements OnInit, OnDestroy {
  @Input() id: string = '';
  @Input() headerText: string = '';
  @Input() dialogType: string = 'danger';
  @Input() confirmText: string = 'Yes';
  @Input() confirmBtnClass: string = 'default';
  @Input() cancelText: string = 'No';
  @Input() cancelBtnClass: string = 'secondary';
  @Input() size: 'sm' | 'lg' | 'xl' = 'sm';
  @Output() actionExecuted: EventEmitter<string> = new EventEmitter();

  private element: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', el => {
      if (el.target.className === 'modal-popup') {
        this.onActionExecute('cancel');
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    $(`#${this.id}Modal`).modal('show');
  }

  // close modal
  close(): void {
    $(`#${this.id}Modal`).modal('hide');
  }

  onActionExecute($event: string): void {
    this.actionExecuted.emit($event);
  }
}
