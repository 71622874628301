<div class="row p-2" *ngIf="totalItems > 0">
  <div class="col-6">
    {{ beginIndex }} to {{ endIndex }} of {{ totalItems }}
  </div>
  <div class="col-6">
    <nav aria-label="Paginator">
      <ul class="pagination pagination-sm justify-content-end">
        <li class="page-item d-none d-sm-block" [class.disabled]="currentPage === 0"><a class="page-link hand" (click)="onActionExecute('goto', 1)">First</a></li>
        <li class="page-item" [class.disabled]="currentPage === 0"><a class="page-link hand" (click)="onActionExecute('prev', 0)">Previous</a></li>
        <li class="page-item d-none d-sm-block"><a class="page-link border-top-0 border-bottom-0"> Page {{currentPage + 1}} of {{totalPages}} </a></li>
        <li class="page-item" [class.disabled]="currentPage === (totalPages - 1)"><a class="page-link hand" (click)="onActionExecute('next', 0)">Next</a></li>
        <li class="page-item d-none d-sm-block" [class.disabled]="currentPage === (totalPages - 1)"><a class="page-link hand" (click)="onActionExecute('goto', totalPages)">Last</a></li>
      </ul>
    </nav>
  </div>
</div>
