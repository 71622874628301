export class PagedModel<T> {
  Items: T[];
  TotalItems: number;
}

export const DefaultPagerOption = 25;

export const DefaultPagerOptions = [
    10,
    25,
    50,
    100
];