<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <a href="/" class="brand-link">
    <img src="/assets/noplant.png" alt="PlantTAGG Logo" class="brand-image">
    <span class="brand-text font-weight-light">PlantTAGG</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">

    <ng-container *ngIf="roleId != 7">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img [src]="authentication.Photo" onError="this.src='/assets/nophoto.jpg'" class="img-circle" alt="User Image">
        </div>
        <div class="info">
          <a routerLink="/user/profile" class="d-block">{{authentication.Fullname}}</a>
        </div>
      </div>
    </ng-container>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu"
        data-accordion="false">
        <ng-template [ngIf]="roleId === 6 || roleId === 7" [ngIfElse]="NoImageGroomer">
          <li class="nav-item">
            <a routerLink="/home/plant-list" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-plant-admin"></i>
              <p>
                Plant List
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/messaging/notification-list" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-plant-admin"></i>
              <p>In-App Messaging</p>
            </a>
          </li>
        </ng-template>
        <ng-template #NoImageGroomer>
          <li class="nav-item" *ngIf="roleId != 3">
            <a routerLink="/dashboard" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <i class="nav-icon icon-dashboard"></i>
              <p>
                Dashboard
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview menu-open">
            <a href="#" class="nav-link">
              <i class="nav-icon icon-plant-admin"></i>
              <p>
                Plant Admin
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="/home/plant-list" class="nav-link" routerLinkActive="active">
                  <p>
                    Plant List
                  </p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/messaging/notification-list" class="nav-link" routerLinkActive="active">
                  <i class="nav-icon icon-plant-admin"></i>
                  <p>In-App Messaging</p>
                </a>
              </li>
            </ul>
          </li>
          <ng-template [ngIf]="roleId != 3">
            <li class="nav-item has-treeview menu-open">
              <a href="#" class="nav-link">
                <i class="nav-icon icon-plantcare-admin"></i>
                <p>
                  Plant Care Admin
                  <i class="fas fa-angle-left right"></i>
                  <span class="badge badge-info right">{{categories.length}}</span>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <a routerLink="/home/overview" class="nav-link" routerLinkActive="active">
                    <p>
                      Overview
                    </p>
                  </a>
                </li>
                <li class="nav-item has-treeview" *ngIf="categories && categories.length > 0">
                  <a href="#" class="nav-link">
                    <p>
                      Plant Care Detail
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  <ul class="nav nav-treeview">
                    <!--Dynamic-->
                    <li *ngFor="let category of categories" class="nav-item {{category.Id}}">
                      <a routerLink="/home/category/{{category.Id}}/season/0/care/0" class="nav-link"
                        routerLinkActive="active">
                        {{category.Name}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-template>
          <li class="nav-item">
            <a routerLink="/styles" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-landscape-admin"></i>
              <p>
                Landscape Admin
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/gallery" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-user-gallery"></i>
              <p>
                User Gallery
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/weather" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-weather-map"></i>
              <p>
                Weather Station Map
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/search" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-searchadmin"></i>
              <p>
                Search Admin
              </p>
            </a>
          </li>
          <li class="nav-item" *ngIf="roleId === 1">
            <a routerLink="/partners" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-partneradmin"></i>
              <p>
                Partner Admin
              </p>
            </a>
          </li>
          <li class="nav-item" *ngIf="roleId === 1">
            <a routerLink="/users/list" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-user-admin"></i>
              <p>
                User Admin
              </p>
            </a>
          </li>
          <li class="nav-item" *ngIf="roleId === 1">
            <a routerLink="/subscribers" class="nav-link" routerLinkActive="active">
              <i class="nav-icon icon-subscriberadmin"></i>
              <p>
                Subscriber Admin
              </p>
            </a>
          </li>
          <!-- <li class="nav-item" *ngIf="roleId === 1">
            <a routerLink="/reports/trigger-reviews" class="nav-link" routerLinkActive="active">
              <p>
                Trigger Reviews
              </p>
            </a>
          </li> -->
          <li class="nav-item" *ngIf="roleId === 1">
            <a routerLink="/openai/playground" class="nav-link" routerLinkActive="active">
              <p>
                OpenAI
              </p>
            </a>
          </li>
        </ng-template>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>