import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from './../services/loading/loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private loader: LoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('ignoreLoadingBar')) {
            return next.handle(request.clone({ headers: request.headers.delete('ignoreLoadingBar') }));
        }

        const r = next.handle(request);
        let started = false;
        const responseSubscribe = r.subscribe.bind(r);
        r.subscribe = (...args) => {
          this.loader.start();
          started = true;
          return responseSubscribe(...args);
        };
    
        return r.pipe(
          finalize(() => started && this.loader.complete()),
        );
    }
}
