export class CategoryModel {
    Id?: number;
    Name?: string;
    Description?: string;
}

export class CategoryGroup extends CategoryModel {
    Items: CategoryModel[];
}

export class CategoryLink {
    Id: number;
    FK1: number;
    FK2: number;
    FK3: number;
    FK4: number;
    UT: number;
    Body: string;
    Count: number;
}

export class CategoryCsvRow {
    C1: string;
    C2: string;
    C3: string;
    C4: string;
    C5: string;
    C6: string;
    C7: string;
}

export class CategoryOverviewMatrixModel {
    Rows: CategoryModel[];
    ColGroups: CategoryGroup[];
    Columns: CategoryModel[];
    Cells: CategoryLink[];
    Writers: CategoryModel[];
}

export class CategoryOverviewCsvMatrixModel {
    Rows: CategoryCsvRow[];
}

export class CareUpdatesModel {
    CareUpdateID: number;
    CareCategoryID: number;
    CareCategoryName: string;
    CareCategoryIcon: string;
    CategoryID: number;
    CategoryName: string;
    CategoryDesc: string;
    LastEditDateTime: number;
    Body: string;
    SiteID?: number;
    EditedByPortalUserID?: number;
    EditerByPortalUserFullName?: string;
    SeasonCategoryID?: number;
    SeasonCategoryName?: string;
    StationID?: number;
    StationName?: string;
    TaskId: number;
    Recur: number;
    FrequencyId: number;
    FrequencyName: string;
    IsTip: number;
}

export class CareUpdateInfoModel {
    CareUpdates?: CareUpdatesModel[];
    Category?: CategoryModel;
    Frequencies?: CategoryModel[];
}

export class TaskRuleModel {
    Id: number;
    TaskId: number;
    Recur: number;
    CareUpdateId: number;
    FrequencyId: number;
    IsTip: number;
}

