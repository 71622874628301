import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { BulkActionLabels, BulkActions, DefaultPagerOption, DefaultPagerOptions, KeyValueModel } from './../../../models';

@Component({
    selector: 'pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnChanges {
    @Input() pagerOptions: number[] = DefaultPagerOptions;
    @Input() pageSize: number = DefaultPagerOption;
    @Input() addPadding = true;
    @Input() showBulk = false;
    @Input() isBulkDisabled: boolean = true;
    @Input() selectedBulkAction: BulkActions = BulkActions.Select;
    @Input() title: string = '';

    @Output() onPagerChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() onBulkAction: EventEmitter<BulkActions> = new EventEmitter<BulkActions>();

    BulkActions = BulkActions;
    bulkAction: KeyValueModel;
    bulkActions: KeyValueModel[] = [];

    constructor() {
        this.bulkAction = { Key: BulkActions.Select, Value: BulkActionLabels.get(this.selectedBulkAction) };

        BulkActionLabels.forEach((label, value) => {
            this.bulkActions.push({ Key: value, Value: label });
        });
    }

	ngOnChanges(): void {
		if (this.selectedBulkAction !== undefined) {
			this.bulkAction = { Key: this.selectedBulkAction, Value: BulkActionLabels.get(this.selectedBulkAction) };
		}
	}

    select(option: number): void {
        this.onPagerChanged.emit(option);
    }
    
	bulk(option: KeyValueModel): void {
		this.bulkAction = option;
	}

	bulkApply(): void {
		this.onBulkAction.emit(this.bulkAction.Key);
	}
}
