import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnChanges {
    @Input() totalRows: number;
    @Input() totalItems: number;
    @Input() currentPage: number;
    @Input() pageSize: number;
    @Output() onPageChanged: EventEmitter<number> = new EventEmitter<number>();

    beginIndex: number = 0;
    endIndex: number = 0;
    totalPages: number = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            this.beginIndex = (this.currentPage * this.pageSize) + 1;
            this.endIndex = this.beginIndex + (this.totalRows - 1);
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        }
    }

    count(length: number): Array<number> {
        return Array.from({ length: length }, (v, k) => k + 1);
    }

    onActionExecute(type: string, page: number): void {
        switch (type) {
            case "prev":
                if (this.currentPage > 0) {
                    this.onPageChanged.emit(this.currentPage - 1);
                }
                break;
            case "next":
                if (this.currentPage < (this.totalPages - 1)) {
                    this.onPageChanged.emit(this.currentPage + 1);
                }
                break;
            case "goto":
                this.onPageChanged.emit(page - 1);
                break;
        }
    }
}
