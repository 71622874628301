import { Component, Input } from '@angular/core';

@Component({
  selector: 'image-with-loading',
  templateUrl: './image-with-loading.component.html',
  styleUrls: ['./image-with-loading.component.css']
})
export class ImageWithLoadingComponent {
  @Input() height:number=200;
  @Input() width:number=200;
  @Input() image:string;

  isLoading:boolean;

  constructor() {
    this.isLoading=true;
  }

  hideLoader(){
    this.isLoading=false;
  }

  hideErrorLoader(){
      this.image = '/assets/noplant.png';
      this.isLoading=false;
  }

}
