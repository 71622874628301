export class LoginRequestModel {
  Username: string;
  Password: string;
  RememberMe: boolean = false;
}

export class ResetRequestModel {
  Key: string;
  NewPassword: string;
  ConfirmPassword: string;
  IsPartner: boolean;

  constructor() {
    this.IsPartner = false;
  }
}
