import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { SessionModel, LoginRequestModel, UserModel, ResetRequestModel } from '../models';
import { environment } from './../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  Identity: SessionModel;

  constructor(private http: HttpClient) {
    const user = JSON.parse(localStorage.getItem(environment.appId));
    this.Identity = new SessionModel();
    if (user && user.TokenKey) {
      this.Identity.isAuth = true;
      this.Identity.TokenKey = user.TokenKey;
      this.Identity.Fullname = user.Fullname;
      this.Identity.Photo = user.Photo;
      this.Identity.UserID = user.UserID;
      this.Identity.RoleID = user.RoleID;
      this.Identity.RoleName = user.RoleName;
      this.Identity.LastLoginDate = user.LastLoginDate;
      this.Identity.Notes = user.Notes;
      this.Identity.ExpirationTimeout = user.ExpirationTimeout;
    }
  }

  login(request: LoginRequestModel) {
    return this.http.post<any>(`${environment.apiUrl}/account/`, request)
      .pipe(map(data => {
        if (data && data.code === 0) {
          this.Identity = new SessionModel();
          this.Identity.isAuth = true;
          this.Identity.TokenKey = data.TokenKey;
          this.Identity.Fullname = data.Fullname;
          this.Identity.Photo = data.Photo;
          this.Identity.UserID = data.UserID;
          this.Identity.RoleID = data.RoleID;
          this.Identity.RoleName = data.RoleName;
          this.Identity.LastLoginDate = data.LastLoginDate;
          this.Identity.Notes = data.Notes;
          this.Identity.ExpirationTimeout = data.ExpirationTimeout;
          localStorage.setItem(environment.appId, JSON.stringify(this.Identity));
        }

        return data;
      }));
  }

  forgot(request: LoginRequestModel) {
    return this.http.post<any>(`${environment.apiUrl}/account/0/forgot`, request);
  }

  reset(request: ResetRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/account/0/reset`, request);
  }

  logout() {
    localStorage.removeItem(environment.appId);
    this.Identity = null;
  }

  me() {
    return this.http.get<UserModel>(`${environment.apiUrl}/portaluser/${this.Identity.UserID}/`);
  }

  setPageSize(id: number, size: number) {
    const request = new LoginRequestModel();
    return this.http.put<any>(`${environment.apiUrl}/portaluser/${id}/PageSize/${size}`, request)
      .pipe(map(data => {
        if (data && data.code === 0) {
          this.Identity.Notes = '' + size;
          localStorage.setItem(environment.appId, JSON.stringify(this.Identity));
        }

        return data;
      }));
  }

  getUserInfo(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/Users/${id}/`);
  }

}
