export class SubscriberModel {
  Thumbnail: string;
  MobileNumber: string;
  LastName: string;
  FirstName: string;
  Yards: number;
  Plants: number;
  Photos: number;
  Favorites: number;
  Disabled: boolean;
  LastActivity: Date;
  TotalRows: number;
}
