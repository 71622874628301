<div class="row mb-2">
	<div class="col-12">
		<form [formGroup]="searchForm" (ngSubmit)="performSearch()">
			<div class="input-group">
				<input type="text" class="form-control form-control-sm" placeholder="Find by Common or Botanical Name"
					(keyup.enter)="performSearch()" formControlName="criteria" aria-label="Search"
					aria-describedby="search-addon">
				<div class="input-group-append">
					<button class="btn btn-default btn-sm" type="submit" id="search-addon">
						<i class="fa fa-search"></i>
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
<div class="row">
	<div class="col-12 text-right">
		<pager [pageSize]="pageSize" (onPagerChanged)="onPagerChanged($event)"></pager>
	</div>
</div>

<div class="row">
	<div class="col-12 plantlist-panel">
		<table class="table table-condensed table-sm mb-3">
			<thead>
				<tr>
					<th scope="col" class="text-left"></th>
					<th scope="col" class="text-left">
						<span class="hand" (click)="sortBy('Fullname')">Common Name</span>
						<span *ngIf="sortColumn === 'CommonName'" class="badge badge-default">
							<i class="fa" [class.fa-arrow-up]="sortType === 'ASC'" [class.fa-arrow-down]="sortType === 'DESC'"></i>
						</span>
					</th>
					<th scope="col" class="text-left">
						<span class="hand" (click)="sortBy('BotanicalName')">Botanical Name</span>
						<span *ngIf="sortColumn === 'BotanicalName'" class="badge badge-default">
							<i class="fa" [class.fa-arrow-up]="sortType === 'ASC'" [class.fa-arrow-down]="sortType === 'DESC'"></i>
						</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let info of data;">
					<td>
						<i class="far fa-circle hand"
							*ngIf="(selected === undefined) || (selected !== undefined && selected.Id !== info.Id)"
							(click)="selectPlant(info)"></i>
						<i class="far fa-check-circle hand" *ngIf="(selected !== undefined && selected.Id === info.Id)"></i>
						<img *ngIf="info.Thumbnail" [src]="info.Thumbnail" alt='Image' class="ml-2 img-fluid img-thumbnail"
							onError="this.src='/assets/noplant.png'">
						<img *ngIf="!info.Thumbnail" src="/assets/noplant.png" alt='Image' class="ml-2 img-fluid img-thumbnail"
							onError="this.src='/assets/noplant.png'">
					</td>
					<td><span [innerHTML]="info.CommonName"></span></td>
					<td><span [innerHTML]="info.BotanicalName"></span></td>
				</tr>
				<tr *ngIf="data === undefined || data.length === 0">
					<td colspan="3">No data available</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<paginator [totalRows]="totalRows" [totalItems]="totalItems" [currentPage]="currentPage" [pageSize]="pageSize"
	(onPageChanged)="onPageChanged($event)">
</paginator>