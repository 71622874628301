import { DatePipe } from "@angular/common";

export class Utils {

  public static getDateString(unixDate: number, short: boolean = false) {
    if (!unixDate)
      return "";
    let datePipe = new DatePipe("en-US");
    let date: Date = new Date(unixDate);
    return datePipe.transform(date, (short ? 'MM/dd/yyyy' : 'MM/dd/yyyy hh:mm:ss a'));
  }

  public static validatePhoneNumber(str: string): boolean {
    let re = /^\d{10}$/;
    if (!re.test(str)) {
      return false;
    }
    return true;
  }

  public static validatePassword(str: string): boolean {
    let re = /^((?=.*\d)(?=.*[a-zA-Z])(?=.*[@#$%]).{8,40})$/;
    if (!re.test(str)) {
      return false;
    }
    return true;
  }

  public static validateEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      return false;
    }

    return true;
  }

  public static formatBytes(bytes): string {
    if (bytes < 1024) return bytes + " Bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KB";
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MB";
    else return (bytes / 1073741824).toFixed(3) + " GB";
  }

  public static handleError(err: any): string {
    let errormsg: string = "";
    var error = err;
    if ((error !== undefined) && (error.ExceptionMessage !== undefined))
      errormsg = error.ExceptionMessage;

    return Utils.handleErrorMessages(errormsg);
  }

  public static handleErrorMessages(err: string): string {
    let errormsg: string = "An error has occurred while processing your request.";

    if (err == "AccountNotFound") {
      errormsg = "The account provided does not match any of our records.";
    } else if (err == "AccountNotApproved") {
      errormsg = "The account provided is not approved yet. Please contact Administrator.";
    } else if (err == "InvalidKey") {
      errormsg = "The reset key is invalid or expired. Please go to forgot password to start the process again.";
    } else if (err == "InvalidRegisterID") {
      errormsg = "The registration key is invalid or expired. Please contact Administrator.";
    } else if (err == "DuplicateEmail") {
      errormsg = "Email address is already registered. Please change.";
    } else if (err == "DeleteSameUser") {
      errormsg = "The user cannot be deleted because is the same user logged.";
    } else if (err == "InvalidInformation") {
      errormsg = "The account provided does not match any of our records.";
    } else if (err == "InvalidCredentials") {
      errormsg = "Login attempt failed. Please contact support for assistance.";
    } else if (err == "OldPasswordMatch") {
      errormsg = "Old password is not valid.";
    } else {
      errormsg = "An error has occurred while processing your request.";
    }

    return errormsg;
  }

  public static isObject(x: any): x is Object {
    return x != null && typeof x === 'object';
  }

  public static qs(obj: any, prefix: string = ""): string {
    var str = [];
    for (var p in obj) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[k];

      str.push(this.isObject(v) ? this.qs(v, k) : (k) + "=" + encodeURIComponent(v));
    }

    return str.join("&");
  }

  public static downloadFile(data: any, labels: string[], fields: string[], fileName: string) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = fields;
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    // csv.unshift(header.join(','));
    csv.unshift(labels.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a'); // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
