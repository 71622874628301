import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  FilterModel, Store, SubscriberModel
} from '../models';
import { environment } from '../../environments/environment';
import { Utils } from '../helpers/utils';

@Injectable({ providedIn: 'root' })
export class SubscribersService extends Store<SubscriberModel>{

  constructor(private http: HttpClient) {
    super(new SubscriberModel());
  }

  searchSubscribers(filter: FilterModel): Observable<SubscriberModel[]> {
    return this.http.get<SubscriberModel[]>(`${environment.apiUrl}/users/0/search?` + Utils.qs(filter));
  }
}
