export class PartnerLocationModel {
  PartnerID: string;
  LocationID: string;
  Name: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  StateID: number;
  CountryID: number;
  PostalCode: string;
  Radius: number;
  ContactName: string;
  PhoneNumber: string;
  Email: string;
  SecondaryContactName: string;
  SecondaryPhoneNumber: string;
  SecondaryEmail: string;
  OverrideLocation: boolean;
  Latitude: number;
  Longitude: number;
  StatusID: number;
  BannerUrl: string;
  Website: string;
  ActivationCount: number;
}

export class PartnerLocationListingModel extends PartnerLocationModel {
  StatusName: string;
  TotalRows: number;
}
