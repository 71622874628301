import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';

import { NgbModule, NgbModalModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';
import { ColorPickerModule } from 'ngx-color-picker';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { QRCodeModule } from 'angularx-qrcode';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { QuillModule } from 'ngx-quill';

import { CustomDateAdapter, CustomDateParserFormatter } from './shared';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { ServicesModule } from './services/service.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxSpinnerModule,
    NgbModalModule,
    ToastrModule.forRoot({ preventDuplicates: true, enableHtml: true }),
    QuillModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 }),
    QRCodeModule,
    CommonModule,
    ServicesModule,
    AppRoutingModule,
    HttpClientModule,
    ChartsModule,
    DragDropModule,
    GoogleMapsModule,
    ColorPickerModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe, DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
