import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilterModel, UserModel, GenericResponseModel, Dashlet, Yard } from './../../models';
import { environment } from './../../../environments/environment';
import { Utils } from './../../helpers/utils';
import { PlantModel } from 'src/app/models/plant.model';

@Injectable()
export class UsersService {

    constructor(private http: HttpClient) { }

    searchUsers(filter: FilterModel): Promise<UserModel[]> {
        let apiSearchUsers = "/portaluser/0/Search/?" + Utils.qs(filter);
        return this.http.get(environment.apiUrl + apiSearchUsers).toPromise()
            .then(response => response as UserModel[])
            .catch(this.handleError);
    };

    deleteUser(id: number): Promise<GenericResponseModel> {
        let apiPortalUserUrl = "/portaluser/" + id + "/";
        return this.http.delete(environment.apiUrl + apiPortalUserUrl)
            .toPromise()
            .then(response => response as GenericResponseModel)
            .catch(this.handleError);
    }

    resetUserPassword(id: number): Promise<GenericResponseModel> {
        let apiPortalUserUrl = "/portaluser/" + id + "/ResetPassword/";
        return this.http.put(environment.apiUrl + apiPortalUserUrl, null)
            .toPromise()
            .then(response => response as GenericResponseModel)
            .catch(this.handleError);
    }

    userById(id: number): Promise<UserModel> {
        let apiPortalUserUrl = "/portaluser/" + id + "/";
        return this.http.get(environment.apiUrl + apiPortalUserUrl)
            .toPromise()
            .then(response => response as UserModel)
            .catch(this.handleError);
    }

    saveUser(id: number, request: UserModel): Promise<GenericResponseModel> {
        if (id == 0) {
            return this.addUser(request);
        } else {
            return this.updateUser(id, request);
        }
    }

    private addUser(request: UserModel): Promise<GenericResponseModel> {
        let apiPortalUserUrl = "/portaluser/0";
        return this.http.post(environment.apiUrl + apiPortalUserUrl, request)
            .toPromise()
            .then(response => response as GenericResponseModel)
            .catch(this.handleError);
    }

    private updateUser(id: number, request: UserModel): Promise<GenericResponseModel> {
        let apiPortalUserUrl = "/portaluser/" + id;
        return this.http.put(environment.apiUrl + apiPortalUserUrl, request)
            .toPromise()
            .then(response => response as GenericResponseModel)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error has occurred', error);
        return Promise.reject(error.message || error);
    }

    getDashlet(id: number): Observable<Dashlet> {
        return this.http.get<Dashlet>(`${environment.apiUrl}/portaluser/${id}/Dashlet/`);
    };

    getYardPoints(id: number): Observable<Yard[]> {
        return this.http.get<Yard[]>(`${environment.apiUrl}/portaluser/${id}/YardPoints/`);
    };

    getDashboardPlantCount(id: number): Observable<PlantModel[]> {
        return this.http.get<PlantModel[]>(`${environment.apiUrl}/portaluser/${id}/PlantsCount/`);
    };

    getPortalUserById(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/portaluser/${id}/`);
    }

    searchRequestRatings(filter: FilterModel): Observable<any> {
        let endpoint = "/portaluser/0/TriggerReviews/?" + Utils.qs(filter);
        return this.http.get(environment.apiUrl + endpoint);
    };

    addRequestRatings(filter: FilterModel): Observable<any> {
        let endpoint = "/portaluser/0/TriggerReviews/?" + Utils.qs(filter);
        return this.http.post(environment.apiUrl + endpoint, {});
    };

    deleteRequestRatings(id: string): Observable<any> {
        let endpoint = `/portaluser/${id}/TriggerReviews`;
        return this.http.delete(environment.apiUrl + endpoint, {});
    };   
}
