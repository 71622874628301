import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  FilterModel, IRTrainingLogListingModel, IRSettingModel,
  GenericResponseModel, ImageSearchResultModel
} from '../../models';
import { environment } from '../../../environments/environment';
import { Utils } from '../../helpers/utils';

@Injectable()
export class IRAdminService {

  constructor(
    private http: HttpClient
  ) { }

  loadTrainingLog(filter: FilterModel): Observable<IRTrainingLogListingModel[]> {
    return this.http.get<IRTrainingLogListingModel[]>(`${environment.apiUrl}/iradmin/0/traininglog?` + Utils.qs(filter));
  }

  getIRSettings(): Observable<IRSettingModel> {
    return this.http.get<IRSettingModel>(`${environment.apiUrl}/iradmin/0/settings`);
  }

  setIRSettings(settings: IRSettingModel): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/iradmin/0/settings`, settings);
  }

  promoteModel(model: IRTrainingLogListingModel): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/iradmin/0/promote`, model);
  }

  getImageSearchResults(filter: FilterModel): Observable<ImageSearchResultModel[]> {
    return this.http.get<ImageSearchResultModel[]>(`${environment.apiUrl}/iradmin/0/ImageSearchResults?` + Utils.qs(filter));
  }

  getResultImages(id: number, filter: FilterModel): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/iradmin/${id}/ResultImages?` + Utils.qs(filter));
  }

  resolveSearchResults(opts: string) {
    const request = { CSV: opts };
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/iradmin/0/UpdateSearchResult/`, request);
  }
}
