import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { WeatherStationModel, CareUpdatesModel } from './../../models';
import { Observable } from 'rxjs';

@Injectable()
export class WeatherStationService {

  constructor(
    private http: HttpClient
  ) { }

  getWeatherStations(): Observable<WeatherStationModel[]> {
    return this.http.get<WeatherStationModel[]>(`${environment.apiUrl}/WeatherStation/`);
  };

  getWeatherStationCareUpdates(text: string): Promise<Array<CareUpdatesModel>> {
    return this.http.get(`${environment.apiUrl}/CareUpdates/${text}/WeatherStation`)
      .toPromise()
      .then(response => response as Array<CareUpdatesModel>)
      .catch(this.handleError);
  }

  getWeatherStation(id: number): Promise<WeatherStationModel> {
    return this.http.get(`${environment.apiUrl}/WeatherStation/${id}/Detail`)
      .toPromise()
      .then(response => response as WeatherStationModel)
      .catch(this.handleError);
  };

  private handleError(error: any): Promise<any> {
    console.error('An error has occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  };
}
