import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { KeyValueModel } from '../models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {

  constructor(private http: HttpClient) { }

  getRoles() {
    return this.http.get<KeyValueModel[]>(`${environment.apiUrl}/Common/0/Roles`);
  }

  getPartnerRoles() {
    return this.http.get<KeyValueModel[]>(`${environment.apiUrl}/Common/0/PartnerRoles`);
  }

  getCountries(): Observable<KeyValueModel[]> {
    return this.http.get<KeyValueModel[]>(`${environment.apiUrl}/Common/0/Countries`);
  }

  getStates(countryId: number) {
    return this.http.get<KeyValueModel[]>(`${environment.apiUrl}/Common/${countryId}/States`);
  }

  getDemographicValue(demographicCategoryID: number) {
    return this.http.get<KeyValueModel[]>(`${environment.apiUrl}/Common/${demographicCategoryID}/DemographicValues`);
  }

  getRejectReasons(): Observable<KeyValueModel[]> {
    return this.http.get<KeyValueModel[]>(`${environment.apiUrl}/Common/0/RejectionReasons`);
  }
}
