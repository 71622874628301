import { Component, Output, Input, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { LoadingService } from '../../../services';
import { WeatherStationModel } from '../../../models';

@Component({
    selector: 'weather-map',
    templateUrl: './weather-map.component.html',
    styleUrls: ['./weather-map.component.css']
})
export class WeatherMapComponent implements AfterViewInit {
    @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
    @Output() selectEvent: EventEmitter<WeatherStationModel> = new EventEmitter<WeatherStationModel>();

    private _stations: WeatherStationModel[] = [];
    lastmarker: any = [];
    markerMap: any = {};

    WeatherStationResponse: WeatherStationModel[];
    // Map
    private map: google.maps.Map;
    private infoWindow: google.maps.InfoWindow;
    private lat = 38.111489;
    private lng = -95.777892;
    private coordinates = new google.maps.LatLng(this.lat, this.lng);

    private mapOptions: google.maps.MapOptions = {
        center: this.coordinates,
        zoom: 4
    };

    private image = {
        url: 'assets/sprite.png',
        size: new google.maps.Size(20, 22),
        origin: new google.maps.Point(65, 40),
        anchor: new google.maps.Point(0, 32)
    };

    private imageMarkerMarked = {
        url: 'assets/sprite.png',
        size: new google.maps.Size(20, 22),
        origin: new google.maps.Point(66, 62),
        anchor: new google.maps.Point(0, 32)
    };

    constructor(private loading: LoadingService) { }

    ngAfterViewInit(): void {
        this.mapInitializer();
    }

    mapInitializer(): void {
        this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
        this.infoWindow = new google.maps.InfoWindow({content: ''});
        this.refreshMarkers();
    }

    @Input()
    set stations(value: WeatherStationModel[]) {
        if (this._stations !== value) {
            this._stations = value;

            if (this.map) {
                this.refreshMarkers();
            }
        }
    }

    get stations(): WeatherStationModel[] {
        return this._stations;
    }

    private markerHasCoordiante(station: WeatherStationModel, marker: any): boolean {
        return station.Latitude.toFixed(3) === marker.getPosition().lat().toFixed(3)
            && station.Longitude.toFixed(3) === marker.getPosition().lng().toFixed(3);
    }

    private attachMarker(station: WeatherStationModel): void {
        const that = this;
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(station.Latitude, station.Longitude),
            map: this.map,
            icon: this.image,
            draggable: false
        });

        marker.addListener('click', () => {
            // tslint:disable-next-line: no-shadowed-variable
            const weatherItem = that.stations.find(i => that.markerHasCoordiante(i, marker));
            if(weatherItem) {
                if (that.lastmarker.length > 0) {
                    that.lastmarker[0].setIcon(that.image);
                    that.lastmarker[0].setAnimation(null);
                    that.lastmarker = [];
                }

                marker.setIcon(that.imageMarkerMarked);
                marker.setAnimation(google.maps.Animation.BOUNCE);

                that.selectEvent.emit(weatherItem);
                that.map.setZoom(8);
                that.map.setCenter(new google.maps.LatLng(weatherItem.Latitude, weatherItem.Longitude));

                that.lastmarker.push(marker);

                if (weatherItem.InfoWindow) {
                    that.infoWindow.setContent(weatherItem.InfoWindow);
                    that.infoWindow.open(marker.getMap(), marker);
                }
            }
        });

        marker.setMap(this.map);
        that.markerMap[station.StationID] = marker;
    }

    refreshMarkers(): void {
        if (this.stations) {
            this.loading.start();
            this.stations.forEach(i => this.attachMarker(i));
            this.loading.complete();
        }
    }

    // calculateCentroid(stations: WeatherStationModel[]): google.maps.LatLng{
    //     // tslint:disable-next-line: one-variable-per-declaration
    //     let lowx,
    //         highx,
    //         lowy,
    //         highy
    //     const lats = [];
    //     const lngs = [];

    //     // tslint:disable-next-line: prefer-for-of
    //     for (let i=0; i<stations.length; i++) {
    //       lngs.push(stations[i].Longitude);
    //       lats.push(stations[i].Latitude);
    //     }

    //     lats.sort();
    //     lngs.sort();
    //     lowx = lats[0];
    //     highx = lats[stations.length - 1];
    //     lowy = lngs[0];
    //     highy = lngs[stations.length - 1];
    //     const center_x = lowx + ((highx-lowx) / 2);
    //     const center_y = lowy + ((highy - lowy) / 2);
    //     return (new google.maps.LatLng(center_x, center_y));
    // }

    sayHello(value: number) {
        if (this.markerMap[value] !== undefined) {
            const marker = this.markerMap[value];

            if (this.lastmarker.length > 0) {
                this.lastmarker[0].setIcon(this.image);
                this.lastmarker[0].setAnimation(null);
                this.lastmarker = [];
            }

            marker.setIcon(this.imageMarkerMarked);
            marker.setAnimation(google.maps.Animation.BOUNCE);
            this.map.setZoom(8);
            this.map.setCenter(new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng()));

            this.lastmarker.push(marker);
        }
    }
}
