import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.css']
})
export class BaseLayoutComponent implements OnInit, OnDestroy {

  body: HTMLBodyElement = document.getElementsByTagName('body')[0];

  constructor() {
  }

  ngOnInit() {
    this.body.classList.add('skin-green');
    this.body.classList.add('sidebar-mini');
  }

  ngOnDestroy() {
    this.body.classList.remove('skin-green');
    this.body.classList.remove('sidebar-mini');
  }

}
