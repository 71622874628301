import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  state$: Observable<boolean>;
  private _state$: BehaviorSubject<boolean>;
  private _updateCount: number;
  private _timeoutHandle: any = undefined;

  constructor() {
    this._state$ = new BehaviorSubject(false);
    this.state$ = this._state$.asObservable();
    this._updateCount = 0;
  }

  start() { 
    if(this._updateCount == 0){
      this._state$.next(true);
    }
    this._updateCount++;
  }

  complete() {
    if(this._updateCount > 0){
      this._updateCount--;
      if(this._updateCount == 0){
        if(this._timeoutHandle){
          clearTimeout(this._timeoutHandle);
          delete this._timeoutHandle;
        }
        this._timeoutHandle = setTimeout(()=>this._state$.next(false), 300);
      }
    }
  }

  show(){
    this.start();
  }

  hide(){
    this.complete();
  }
}
