import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, Params, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { Subscription } from 'rxjs';

import { UtilsService } from './../../services';

interface IBreadcrumb {
    label: string;
    params?: Params;
    url: string;
}

@Component({
    selector: 'home-breadcrumb',
    templateUrl: './home-breadcrumb.component.html',
    styleUrls: ['./home-breadcrumb.component.css']
})
export class HomeBreadcrumbComponent implements OnInit, OnDestroy {
    @Input() overrideName: string = '';
    public breadcrumbs: IBreadcrumb[];
    navigationSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router) {
        this.breadcrumbs = [];
    }

    ngOnInit(): void {
        this.navigationSubscription = this.router.events.subscribe(value => {
            this.updateBreadcrumbs();
        });

        this.updateBreadcrumbs();
    }

    ngOnDestroy(): void {
      UtilsService.Unsubscribe(this.navigationSubscription);
    }

    updateBreadcrumbs(): void {
        let root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
    }

    getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = [], category: string = ""): IBreadcrumb[] {
        const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

        //get the child routes
        let children: ActivatedRoute[] = route.children;

        //return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }

        //iterate over each children
        for (let child of children) {
            //verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }

            //verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                return this.getBreadcrumbs(child, url, breadcrumbs);
            }

            //get the route's URL segment
            let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

            //append route URL to URL
            url += `/home/${routeURL}`;

            //add breadcrumb
            let label: string = child.snapshot.data[ROUTE_DATA_BREADCRUMB];

            let breadcrumb: IBreadcrumb = {
                label: label,
                params: child.snapshot.params,
                url: url
            };
            breadcrumbs.push(breadcrumb);

            //recursive
            return this.getBreadcrumbs(child, url, breadcrumbs);
        }

        //we should never get here, but just in case
        return breadcrumbs;
    }
}
