<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
     
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fas fa-user"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a (click)="navigateToProfile()" class="dropdown-item">
            <!-- Message Start -->
            <div class="media">
              <img [src]="authentication.Photo" onError="this.src='/assets/nophoto.jpg'" class="img-size-50 mr-3 img-circle" alt="{{authentication.Fullname}}">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  {{authentication.Fullname}}
                </h3>
                <p class="text-sm">{{authentication.RoleName}}</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> {{authentication.LastLoginDate}} GMT</p>
              </div>
            </div>
            <!-- Message End -->
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer" (click)="logout();">Sign out</a>
        </div>        
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
