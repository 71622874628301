import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../services';
import { SessionModel } from 'src/app/models';

@Component({
    selector: 'home-header',
    templateUrl: './home-header.component.html',
    styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {

    authentication: SessionModel;
    isPublisherUser: boolean = false;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
        this.isPublisherUser = this.authenticationService.Identity?.RoleID === 7; //GROOMER ROLE ID
    }

    ngOnInit(): void {
        this.authentication = this.authenticationService.Identity;
        if (this.authentication.Photo) {
            this.authentication.Photo = this.authentication.Photo + "?v=" + (new Date()).getTime();
        }
    }

    logout(): void {
        this.authenticationService.logout();
        this.router.navigate(['/authentication/signin']);
    }

    navigateToProfile(): void {
        if (!this.isPublisherUser) {
            this.router.navigate(['user', 'profile']);
        }
    }
}
