<div class="row" [ngClass]="{ 'p-2': addPadding }" *ngIf="pagerOptions.length > 0">
  <div class="col-8" *ngIf="!showBulk && title.length > 0"><h5>{{ title }}</h5></div>
  <div [class.col-8]="showBulk" *ngIf="showBulk">
    <label for="pageSizeDropDown" class="mr-3">Actions:</label>
    <button class="btn btn-default btn-sm dropdown-toggle" type="button" id="bulkActionDropDown" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      {{ bulkAction?.Value }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a *ngFor="let option of bulkActions;" class="dropdown-item" (click)="bulk(option)">{{ option.Value }}</a>
    </div>
    <button type="button" class="btn btn-primary btn-sm ml-2" title="Apply" (click)="bulkApply()"
      [disabled]="bulkAction?.Key === BulkActions.Select || isBulkDisabled">Apply</button>
    <ng-content></ng-content>
  </div>

  <div class="text-right" [class.col-12]="!showBulk && title.length === 0" [class.col-4]="showBulk || title.length > 0">
    <label for="pageSizeDropDown" class="pull-left mr-3">Page Size:</label>
    <button class="btn btn-default btn-sm dropdown-toggle" type="button" id="pageSizeDropDown" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      {{ pageSize }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a *ngFor="let option of pagerOptions;" class="dropdown-item" (click)="select(option)">{{ option }}</a>
    </div>
  </div>
</div>